import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BookingZone, Button, InputField, TableWrapper } from 'components/Global';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'features/user/slice';
import countryCodes from 'utils/country-codes.json';
import { getDetailAccountBank } from 'features/banks/actions';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { indonesianDateFormat } from 'utils/helpers';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { useAppContext } from 'components/Context/AppContext';
import { fetchCustomerInfo } from 'features/user/actions';
import InputViolation from 'components/Global/InputViolation';
import InputAdditionals from 'components/Global/InputAdditionals';
import AddOnsField from 'components/Global/AddOnsField';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const WithDriverDetail = ({
  orderData: data,
  getFileName,
  imagePreviewHandler,
  newOrderData,
  oldOrderData,
  confirmHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const customer = useSelector(getCustomerData);
  const customerAccountBank = useSelector((state) => state.banks?.detailAccountBank ?? {});

  const { showToast } = useAppContext();

  const approvalMethod = newOrderData?.approval_setting?.name.includes('update order') ? 'update' : 'delete';

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [activeTab, setActiveTab] = useState('Data Sekarang');

  const backHandler = () => {
    navigate(-1);
  };

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    if (!customerId) return;

    dispatch(getDetailAccountBank(customerId));
    dispatch(fetchCustomerInfo(customerId));
  }, [customerId]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data?.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  return (
    <div className="detail-order-with-driver">
      <TableWrapper
        icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
        title="Dengan Supir"
        showExportButton={approvalMethod === 'delete'}
        onExportToPdf={handleExportToPdf}
      >
        {approvalMethod === 'update' ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <div className="detail-order-with-driver__renter-detail">
                <div className="detail-order-with-driver__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-with-driver__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={newOrderData?.data?.user_name || '-'} />
                  <InputField label="Email" disabled value={newOrderData?.data?.email || '-'} />

                  <div className="detail-order-with-driver__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation
                          ? newOrderData?.data?.phone_country_code
                          : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={newOrderData?.data?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-with-driver__renter-detail-body__phone-number">
                    <div className="detail-order-with-driver__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          newOrderData?.data?.is_admin_creation
                            ? newOrderData?.data?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-with-driver__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={newOrderData?.data?.wa_number || '-'} />
                  </div>

                  <div />

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(data?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order__payment-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                          ? getFileName(data?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            data?.is_admin_creation
                              ? data?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-with-driver__rent-detail">
                <div className="detail-order-with-driver__rent-detail-title">
                  <h1>Detail Sewa</h1>
                </div>
                <div className="detail-order-with-driver__rent-detail-body">
                  <div className="detail-order-with-driver__rent-detail-body__order-type">
                    <InputField
                      label="Tipe Sewa"
                      disabled
                      value={newOrderData?.data?.order_type_id === 1 ? 'Harian' : '-'}
                    />
                    <InputField
                      label="Batas Sewa Mobil"
                      disabled
                      value={newOrderData?.data?.order_detail?.vehicle?.rental_duration + ' Jam' || '-'}
                    />
                  </div>

                  <InputField
                    label="Jenis Mobil"
                    disabled
                    value={newOrderData?.data?.order_detail?.vehicle?.name ?? '-'}
                  />

                  <div className="detail-order-with-driver__rent-detail-body__date-time">
                    <InputField
                      label="Tanggal Mulai"
                      disabled
                      value={
                        newOrderData?.data?.order_detail?.start_booking_date
                          ? indonesianDateFormat(newOrderData?.data?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Tanggal Selesai"
                      disabled
                      value={
                        newOrderData?.data?.order_detail?.end_booking_date
                          ? indonesianDateFormat(newOrderData?.data?.order_detail?.end_booking_date)
                          : '-'
                      }
                    />
                  </div>

                  <InputField
                    label="Durasi"
                    disabled
                    value={`${newOrderData?.data?.order_detail?.booking_zones?.length} Hari` || '-'}
                  />

                  <div className="detail-order-with-driver__rent-detail-body__payment-passenger">
                    <InputField
                      label="Tipe Pembayaran"
                      disabled
                      value={
                        newOrderData?.data?.type === 'FULL'
                          ? 'Pembayaran Full'
                          : newOrderData?.data?.type === 'HALF'
                          ? `Pembayaran DP ${
                              newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                                ? `${newOrderData.data.order_detail.dp_rule.formula_percentage.value}%`
                                : ''
                            }`
                          : '-'
                      }
                    />
                    <InputField
                      label="Jumlah Penumpang"
                      icon={<CarChair />}
                      iconPosition="start"
                      disabled
                      value={
                        newOrderData?.data?.order_detail?.passenger_number !== 0
                          ? `${newOrderData?.data?.order_detail?.passenger_number} Orang`
                          : 0
                      }
                    />
                  </div>

                  <div className="with-driver-form__form__baggage-passenger">
                    <InputField
                      label="Kapasitas Koper"
                      value={newOrderData?.data?.order_detail?.vehicle?.max_suitcase || 0}
                      disable
                    />
                    <InputField
                      label="Koper yang dibawa"
                      disabled
                      value={newOrderData?.data?.order_detail?.baggage ?? 0}
                    />
                  </div>
                </div>
              </div>

              <div className="detail-order-with-driver__rent-zone">
                <div className="detail-order-with-driver__rent-detail-title">
                  <h1>Zona Penyewaan</h1>
                </div>
                <BookingZone
                  startDate={new Date(newOrderData?.data?.order_detail?.start_booking_date)}
                  durationRentDate={newOrderData?.data?.order_detail?.booking_zones?.length}
                  selectedCar={newOrderData?.data?.order_detail?.vehicle}
                  orderData={{ order_detail: { booking_zones: newOrderData?.data?.order_detail?.booking_zones } }}
                  isOrderConfirmation={true}
                  forCustomOrder={false}
                  locTimeId={newOrderData?.data?.order_detail?.loc_time_id}
                  rentalLocationId={newOrderData?.data?.order_detail?.location_id}
                />
              </div>

              <div className="detail-order__price-detail-body__input-violation">
                <InputViolation
                  violationList={[]}
                  totalPrice={newOrderData?.data?.order_violations
                    ?.map((item) => item.cost)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  violationPrice={0}
                  data={newOrderData?.data?.order_violations || []}
                  isOnEdit={false}
                />
              </div>
              <div className="detail-order__price-detail-body__input-violation">
                <InputAdditionals
                  additionalList={[]}
                  totalPrice={newOrderData?.data?.order_additional_fees
                    ?.map((item) => item.value)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  additionalPrice={0}
                  data={newOrderData?.data?.order_additional_fees || []}
                  isOnEdit={false}
                />
              </div>

              <div className="detail-order-with-driver__deposit-detail">
                <div className="detail-order-with-driver__deposit-detail-title">
                  <h1>Detail Deposit</h1>
                </div>
                <div className="detail-order-with-driver__deposit-detail-body">
                  <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                  <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                  <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                </div>
              </div>

              <AddOnsField data={newOrderData?.data?.addons} totalPrice={newOrderData?.data?.addon_total_price} />

              <div className="detail-order__price-detail">
                <div className="detail-order__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>
                <div className="detail-order__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(newOrderData?.data?.booking_price) || '-'}
                  />
                  <InputField label="Deposit" disabled value={setCurrency(newOrderData?.data?.deposit) || '-'} />
                  <InputField
                    label="Overtime"
                    disabled
                    value={setCurrency(newOrderData?.data?.over_time_price) || '-'}
                  />
                  {/* <InputField label="One Day Charge" disabled value={setCurrency(newOrderData?.data?.one_day_order_charge) || '-'} /> */}
                  {/* <InputField
          label="Outside Operational Hour Charge"
          disabled
          value={setCurrency(newOrderData?.data?.outside_operational_charge) || '-'}
        /> */}
                  <InputField
                    label="Exceed Max Passenger Charge"
                    disabled
                    value={setCurrency(newOrderData?.data?.exceed_passenger_price) || '-'}
                  />
                  <InputField
                    label="Tipe Pembayaran"
                    disabled
                    value={
                      newOrderData?.data?.type === 'FULL'
                        ? 'Pembayaran Full'
                        : `Pembayaran DP ${
                            newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                              ? `${newOrderData.data.order_detail.dp_rule.formula_percentage?.value}%`
                              : ''
                          }`
                    }
                  />
                  <InputField
                    label={`Biaya Bayar DP ${
                      newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                        ? `${newOrderData.data.order_detail.dp_rule.formula_percentage.value}%`
                        : ''
                    }`}
                    disabled
                    value={setCurrency(newOrderData?.data?.down_payment) || '-'}
                  />
                  <InputField
                    label="Biaya selanjutnya yang harus dibayar"
                    disabled
                    value={setCurrency(newOrderData?.data?.remainder) || '-'}
                  />
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={newOrderData?.data?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  {newOrderData?.data?.type === 'HALF' ? (
                    <InputField
                      label="Status Pembayaran DP"
                      disabled
                      value={newOrderData?.data?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                    />
                  ) : null}
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(newOrderData?.data?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(newOrderData?.data?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(newOrderData?.data?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={newOrderData?.data?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={newOrderData?.data?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(newOrderData?.data?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__payment-detail">
                <div className="detail-order__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div className="detail-order__payment-detail-body__payment-method">
                    <InputField label="Metode Pembayaran" disabled value={getPaymentMethodString(data?.disbursement)} />
                  </div>

                  {Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </TabPane>

            {!checkEmptyObject(oldOrderData) && newOrderData?.approval_setting?.name.includes('update order') ? (
              <TabPane label="Data Sebelum">
                <div className="detail-order-with-driver__renter-detail">
                  <div className="detail-order-with-driver__renter-detail-title">
                    <h1>Detail Penyewa</h1>
                  </div>
                  <div className="detail-order-with-driver__renter-detail-body">
                    <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                    <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                    <div className="detail-order-with-driver__renter-detail-body__phone-number">
                      <InputField
                        label="No Handphone"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation
                            ? oldOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <InputField value={oldOrderData?.phone_number || '-'} disabled />
                    </div>

                    <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                    <div className="detail-order-with-driver__renter-detail-body__phone-number">
                      <div className="detail-order-with-driver__renter-detail-body__phone-number-wrapper">
                        <InputField
                          label="Whatsapp"
                          disabled
                          value={
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.phone_country_code
                              : customer?.phone_code || '-'
                          }
                          icon={
                            <img
                              src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                              width={26}
                              height={16}
                              alt={selectedCountry?.name + ' nation flag'}
                            />
                          }
                        />
                        <div className="detail-order-with-driver__renter-detail-body__image-wrapper"></div>
                      </div>
                      <InputField disabled value={oldOrderData?.wa_number || '-'} />
                    </div>

                    <div />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="KTP"
                        disabled
                        value={
                          data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                            ? getFileName(data?.order_detail?.identity?.ktp)
                            : customer?.PersonalInfos?.ktp
                            ? getFileName(customer?.PersonalInfos?.ktp)
                            : 'Belum Upload KTP'
                        }
                        className="preview-image-input"
                      />
                      {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                      customer?.PersonalInfos?.ktp ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              data?.is_admin_creation
                                ? data?.order_detail?.identity?.ktp
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="SIM"
                        disabled
                        value={
                          data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                            ? getFileName(data?.order_detail?.identity?.sim)
                            : customer?.PersonalInfos?.sim
                            ? getFileName(customer?.PersonalInfos?.sim)
                            : 'Belum Upload SIM'
                        }
                        className="preview-image-input"
                      />
                      {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                      customer?.PersonalInfos?.sim ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              data?.is_admin_creation
                                ? data?.order_detail?.identity?.sim
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="detail-order-with-driver__rent-detail">
                  <div className="detail-order-with-driver__rent-detail-title">
                    <h1>Detail Sewa</h1>
                  </div>
                  <div className="detail-order-with-driver__rent-detail-body">
                    <div className="detail-order-with-driver__rent-detail-body__order-type">
                      <InputField
                        label="Tipe Sewa"
                        disabled
                        value={oldOrderData?.order_type_id === 1 ? 'Harian' : '-'}
                      />
                      <InputField
                        label="Batas Sewa Mobil"
                        disabled
                        value={oldOrderData?.order_detail?.vehicle?.rental_duration + ' Jam' || '-'}
                      />
                    </div>
                    <InputField label="Jenis Mobil" disabled value={oldOrderData?.order_detail?.vehicle?.name ?? '-'} />

                    <div className="detail-order-with-driver__rent-detail-body__date-time">
                      <InputField
                        label="Tanggal Mulai"
                        disabled
                        value={
                          oldOrderData?.order_detail?.start_booking_date
                            ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                            : '-'
                        }
                      />
                      <InputField
                        label="Tanggal Selesai"
                        disabled
                        value={
                          oldOrderData?.order_detail?.end_booking_date
                            ? indonesianDateFormat(oldOrderData?.order_detail?.end_booking_date)
                            : '-'
                        }
                      />
                    </div>

                    <InputField
                      label="Durasi"
                      disabled
                      value={`${oldOrderData?.order_detail?.booking_zones?.length} Hari` || '-'}
                    />

                    <div className="detail-order-with-driver__rent-detail-body__payment-passenger">
                      <InputField
                        label="Tipe Pembayaran"
                        disabled
                        value={
                          oldOrderData?.type === 'FULL'
                            ? 'Pembayaran Full'
                            : oldOrderData?.type === 'HALF'
                            ? `Pembayaran DP ${
                                oldOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                                  ? `${oldOrderData.order_detail.dp_rule.formula_percentage.value}%`
                                  : ''
                              }`
                            : '-'
                        }
                      />
                      <InputField
                        label="Jumlah Penumpang"
                        icon={<CarChair />}
                        iconPosition="start"
                        disabled
                        value={
                          oldOrderData?.order_detail?.passenger_number !== 0
                            ? `${oldOrderData?.order_detail?.passenger_number} Orang`
                            : 0
                        }
                      />
                    </div>

                    <div className="with-driver-form__form__baggage-passenger">
                      <InputField
                        label="Kapasitas Koper"
                        value={oldOrderData?.order_detail?.vehicle?.max_suitcase || 0}
                        disable
                      />
                      <InputField label="Koper yang dibawa" disabled value={oldOrderData?.order_detail?.baggage ?? 0} />
                    </div>
                  </div>
                </div>

                <div className="detail-order-with-driver__rent-zone">
                  <div className="detail-order-with-driver__rent-detail-title">
                    <h1>Zona Penyewaan</h1>
                  </div>
                  <BookingZone
                    startDate={new Date(oldOrderData?.order_detail?.start_booking_date)}
                    durationRentDate={oldOrderData?.order_detail?.booking_zones?.length}
                    selectedCar={oldOrderData?.order_detail?.vehicle}
                    orderData={{ order_detail: { booking_zones: oldOrderData?.order_detail?.booking_zones } }}
                    isOrderConfirmation={true}
                    forCustomOrder={false}
                    locTimeId={data?.order_detail?.loc_time_id}
                    rentalLocationId={data?.order_detail?.location_id}
                  />
                </div>

                <div className="detail-order__price-detail-body__input-violation">
                  <InputViolation
                    violationList={[]}
                    totalPrice={oldOrderData?.order_violations
                      ?.map((item) => item.cost)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    violationPrice={0}
                    data={oldOrderData?.order_violations || []}
                    isOnEdit={false}
                  />
                </div>
                <div className="detail-order__price-detail-body__input-violation">
                  <InputAdditionals
                    additionalList={[]}
                    totalPrice={oldOrderData?.order_additional_fees
                      ?.map((item) => item.value)
                      ?.reduce((acc, cVal) => acc + cVal, 0)}
                    additionalPrice={0}
                    data={oldOrderData?.order_additional_fees || []}
                    isOnEdit={false}
                  />
                </div>

                <div className="detail-order-with-driver__deposit-detail">
                  <div className="detail-order-with-driver__deposit-detail-title">
                    <h1>Detail Deposit</h1>
                  </div>
                  <div className="detail-order-with-driver__deposit-detail-body">
                    <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                    <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                    <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                  </div>
                </div>

                <AddOnsField data={oldOrderData?.addons} totalPrice={oldOrderData?.addon_total_price} />

                <div className="detail-order__price-detail">
                  <div className="detail-order__price-detail-title">
                    <h1>Rincian Biaya</h1>
                  </div>
                  <div className="detail-order__price-detail-body">
                    <InputField
                      label="Biaya Sewa Mobil"
                      disabled
                      value={setCurrency(oldOrderData?.booking_price) || '-'}
                    />
                    <InputField label="Deposit" disabled value={setCurrency(oldOrderData?.deposit) || '-'} />
                    <InputField label="Overtime" disabled value={setCurrency(oldOrderData?.over_time_price) || '-'} />
                    {/* <InputField label="One Day Charge" disabled value={setCurrency(oldOrderData?.one_day_order_charge) || '-'} /> */}
                    {/* <InputField
          label="Outside Operational Hour Charge"
          disabled
          value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
        /> */}
                    <InputField
                      label="Exceed Max Passenger Charge"
                      disabled
                      value={setCurrency(oldOrderData?.exceed_passenger_price) || '-'}
                    />
                    <InputField
                      label="Tipe Pembayaran"
                      disabled
                      value={
                        oldOrderData?.type === 'FULL'
                          ? 'Pembayaran Full'
                          : `Pembayaran DP ${
                              oldOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                                ? `${oldOrderData.order_detail.dp_rule.formula_percentage?.value}%`
                                : ''
                            }`
                      }
                    />
                    <InputField
                      label={`Biaya Bayar DP ${
                        oldOrderData?.order_detail?.dp_rule?.formula_percentage?.value
                          ? `${oldOrderData.order_detail.dp_rule.formula_percentage.value}%`
                          : ''
                      }`}
                      disabled
                      value={setCurrency(oldOrderData?.down_payment) || '-'}
                    />
                    <InputField
                      label="Biaya selanjutnya yang harus dibayar"
                      disabled
                      value={setCurrency(oldOrderData?.remainder) || '-'}
                    />
                    <InputField
                      label="Pembayaran Dengan Point"
                      disabled
                      value={oldOrderData?.point}
                      icon={<PointsIcon />}
                      iconPosition="start"
                    />
                    {oldOrderData?.type === 'HALF' ? (
                      <InputField
                        label="Status Pembayaran DP"
                        disabled
                        value={oldOrderData?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                      />
                    ) : null}
                    <InputField
                      label="Charge Payment Gateway Platform"
                      disabled
                      value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                    />
                    <InputField
                      label="Customer Service Platform"
                      disabled
                      value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                    />
                    <InputField
                      label="Total Keseluruhan"
                      disabled
                      value={setCurrency(oldOrderData?.total_payment) || '-'}
                    />
                  </div>
                </div>

                <div className="detail-order__voucher-detail">
                  <div className="detail-order__voucher-detail-title">
                    <h1>Voucher</h1>
                  </div>
                  <div className="detail-order__voucher-detail-body">
                    <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                    <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                    <InputField
                      label="Harga Potongan"
                      disabled
                      value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                    />
                  </div>
                </div>

                <div className="detail-order__payment-detail">
                  <div className="detail-order__payment-detail-title">
                    <h1>Pembayaran</h1>
                  </div>
                  <div className="detail-order__payment-detail-body">
                    <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                    <div className="detail-order__payment-detail-body__payment-method">
                      <InputField
                        label="Metode Pembayaran"
                        disabled
                        value={getPaymentMethodString(data?.disbursement)}
                      />
                    </div>

                    {Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                      <>
                        <InputField
                          label="Nama Rekening"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_name
                              : data?.disbursement?.sender_name || '-'
                          }
                        />

                        <InputField
                          label="Nama Bank"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                              : data?.disbursement?.sender_bank_name || '-'
                          }
                        />

                        <div className="detail-order__payment-detail-body__preview-image">
                          <InputField
                            label="Foto Bukti Transfer"
                            disabled
                            value={getFileName(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement.disbursement_confirmation_image,
                            )}
                            className="preview-image-input"
                          />

                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                  ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                  : data?.disbursement.disbursement_confirmation_image,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </TabPane>
            ) : (
              <div />
            )}
          </Tabs>
        ) : (
          <>
            <div className="detail-order-with-driver__renter-detail">
              <div className="detail-order-with-driver__renter-detail-title">
                <h1>Detail Penyewa</h1>
              </div>
              <div className="detail-order-with-driver__renter-detail-body">
                <InputField label="Nama Lengkap" disabled value={newOrderData?.data?.user_name || '-'} />
                <InputField label="Email" disabled value={newOrderData?.data?.email || '-'} />

                <div className="detail-order-with-driver__renter-detail-body__phone-number">
                  <InputField
                    label="No Handphone"
                    disabled
                    value={
                      newOrderData?.data?.is_admin_creation
                        ? newOrderData?.data?.phone_country_code
                        : customer?.phone_code || '-'
                    }
                    icon={
                      <img
                        src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                        width={26}
                        height={16}
                        alt={selectedCountry?.name + ' nation flag'}
                      />
                    }
                  />
                  <InputField value={newOrderData?.data?.phone_number || '-'} disabled />
                </div>

                <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                <div className="detail-order-with-driver__renter-detail-body__phone-number">
                  <div className="detail-order-with-driver__renter-detail-body__phone-number-wrapper">
                    <InputField
                      label="Whatsapp"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation
                          ? newOrderData?.data?.phone_country_code
                          : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <div className="detail-order-with-driver__renter-detail-body__image-wrapper"></div>
                  </div>
                  <InputField disabled value={newOrderData?.data?.wa_number || '-'} />
                </div>

                <div />

                <div className="detail-order__payment-detail-body__preview-image">
                  <InputField
                    label="KTP"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                        ? getFileName(data?.order_detail?.identity?.ktp)
                        : customer?.PersonalInfos?.ktp
                        ? getFileName(customer?.PersonalInfos?.ktp)
                        : 'Belum Upload KTP'
                    }
                    className="preview-image-input"
                  />
                  {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                  customer?.PersonalInfos?.ktp ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.ktp
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                </div>

                <div className="detail-order__payment-detail-body__preview-image">
                  <InputField
                    label="SIM"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                        ? getFileName(data?.order_detail?.identity?.sim)
                        : customer?.PersonalInfos?.sim
                        ? getFileName(customer?.PersonalInfos?.sim)
                        : 'Belum Upload SIM'
                    }
                    className="preview-image-input"
                  />
                  {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                  customer?.PersonalInfos?.sim ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.sim
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="detail-order-with-driver__rent-detail">
              <div className="detail-order-with-driver__rent-detail-title">
                <h1>Detail Sewa</h1>
              </div>
              <div className="detail-order-with-driver__rent-detail-body">
                <InputField
                  label="Tipe Sewa"
                  disabled
                  value={newOrderData?.data?.order_type_id === 1 ? 'Harian' : '-'}
                />
                <InputField
                  label="Jenis Mobil"
                  disabled
                  value={newOrderData?.data?.order_detail?.vehicle?.name ?? '-'}
                />

                <div className="detail-order-with-driver__rent-detail-body__date-time">
                  <InputField
                    label="Tanggal Mulai"
                    disabled
                    value={
                      newOrderData?.data?.order_detail?.start_booking_date
                        ? indonesianDateFormat(newOrderData?.data?.order_detail?.start_booking_date)
                        : '-'
                    }
                  />
                  <InputField
                    label="Tanggal Selesai"
                    disabled
                    value={
                      newOrderData?.data?.order_detail?.end_booking_date
                        ? indonesianDateFormat(newOrderData?.data?.order_detail?.end_booking_date)
                        : '-'
                    }
                  />
                </div>

                <InputField
                  label="Durasi"
                  disabled
                  value={`${newOrderData?.data?.order_detail?.booking_zones?.length} Hari` || '-'}
                />

                <div className="detail-order-with-driver__rent-detail-body__payment-passenger">
                  <InputField
                    label="Tipe Pembayaran"
                    disabled
                    value={
                      newOrderData?.data?.type === 'FULL'
                        ? 'Pembayaran Full'
                        : newOrderData?.data?.type === 'HALF'
                        ? `Pembayaran DP ${
                            newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                              ? `${newOrderData.data.order_detail.dp_rule.formula_percentage.value}%`
                              : ''
                          }`
                        : '-'
                    }
                  />
                  <InputField
                    label="Jumlah Penumpang"
                    icon={<CarChair />}
                    iconPosition="start"
                    disabled
                    value={
                      newOrderData?.data?.order_detail?.passenger_number !== 0
                        ? `${newOrderData?.data?.order_detail?.passenger_number} Orang`
                        : 0
                    }
                  />
                </div>

                <div className="with-driver-form__form__baggage-passenger">
                  <InputField
                    label="Kapasitas Koper"
                    value={newOrderData?.data?.order_detail?.vehicle?.max_suitcase || 0}
                    disable
                  />
                  <InputField
                    label="Koper yang dibawa"
                    disabled
                    value={newOrderData?.data?.order_detail?.baggage ?? 0}
                  />
                </div>
              </div>
            </div>

            <div className="detail-order-with-driver__rent-zone">
              <div className="detail-order-with-driver__rent-detail-title">
                <h1>Zona Penyewaan</h1>
              </div>
              <BookingZone
                startDate={new Date(newOrderData?.data?.order_detail?.start_booking_date)}
                durationRentDate={newOrderData?.data?.order_detail?.booking_zones?.length}
                selectedCar={newOrderData?.data?.order_detail?.vehicle}
                orderData={{ order_detail: { booking_zones: newOrderData?.data?.order_detail?.booking_zones } }}
                isOrderConfirmation={true}
                forCustomOrder={false}
                locTimeId={newOrderData?.data?.order_detail?.loc_time_id}
                rentalLocationId={newOrderData?.data?.order_detail?.location_id}
              />
            </div>

            <div className="detail-order__price-detail-body__input-violation">
              <InputViolation
                violationList={[]}
                totalPrice={newOrderData?.data?.order_violations
                  ?.map((item) => item.cost)
                  ?.reduce((acc, cVal) => acc + cVal, 0)}
                violationPrice={0}
                data={newOrderData?.data?.order_violations || []}
                isOnEdit={false}
              />
            </div>
            <div className="detail-order__price-detail-body__input-violation">
              <InputAdditionals
                additionalList={[]}
                totalPrice={newOrderData?.data?.order_additional_fees
                  ?.map((item) => item.value)
                  ?.reduce((acc, cVal) => acc + cVal, 0)}
                additionalPrice={0}
                data={newOrderData?.data?.order_additional_fees || []}
                isOnEdit={false}
              />
            </div>

            <div className="detail-order-with-driver__deposit-detail">
              <div className="detail-order-with-driver__deposit-detail-title">
                <h1>Detail Deposit</h1>
              </div>
              <div className="detail-order-with-driver__deposit-detail-body">
                <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
              </div>
            </div>

            <AddOnsField data={newOrderData?.data?.addons} totalPrice={newOrderData?.data?.addon_total_price} />

            <div className="detail-order__price-detail">
              <div className="detail-order__price-detail-title">
                <h1>Rincian Biaya</h1>
              </div>
              <div className="detail-order__price-detail-body">
                <InputField
                  label="Biaya Sewa Mobil"
                  disabled
                  value={setCurrency(newOrderData?.data?.booking_price) || '-'}
                />
                <InputField label="Deposit" disabled value={setCurrency(newOrderData?.data?.deposit) || '-'} />
                <InputField label="Overtime" disabled value={setCurrency(newOrderData?.data?.over_time_price) || '-'} />
                {/* <InputField label="One Day Charge" disabled value={setCurrency(newOrderData?.data?.one_day_order_charge) || '-'} /> */}
                {/* <InputField
          label="Outside Operational Hour Charge"
          disabled
          value={setCurrency(newOrderData?.data?.outside_operational_charge) || '-'}
        /> */}
                <InputField
                  label="Exceed Max Passenger Charge"
                  disabled
                  value={setCurrency(newOrderData?.data?.exceed_passenger_price) || '-'}
                />
                <InputField
                  label="Tipe Pembayaran"
                  disabled
                  value={
                    newOrderData?.data?.type === 'FULL'
                      ? 'Pembayaran Full'
                      : `Pembayaran DP ${
                          newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                            ? `${newOrderData.data.order_detail.dp_rule.formula_percentage?.value}%`
                            : ''
                        }`
                  }
                />
                <InputField
                  label={`Biaya Bayar DP ${
                    newOrderData?.data?.order_detail?.dp_rule?.formula_percentage?.value
                      ? `${newOrderData.data.order_detail.dp_rule.formula_percentage.value}%`
                      : ''
                  }`}
                  disabled
                  value={setCurrency(newOrderData?.data?.down_payment) || '-'}
                />
                <InputField
                  label="Biaya selanjutnya yang harus dibayar"
                  disabled
                  value={setCurrency(newOrderData?.data?.remainder) || '-'}
                />
                <InputField
                  label="Pembayaran Dengan Point"
                  disabled
                  value={newOrderData?.data?.point}
                  icon={<PointsIcon />}
                  iconPosition="start"
                />
                {newOrderData?.data?.type === 'HALF' ? (
                  <InputField
                    label="Status Pembayaran DP"
                    disabled
                    value={newOrderData?.data?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
                  />
                ) : null}
                <InputField
                  label="Charge Payment Gateway Platform"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_external_payment_fee) || '-'}
                />
                <InputField
                  label="Customer Service Platform"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_customer_service_fee) || '-'}
                />
                <InputField
                  label="Total Harga Bersih"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_net_amount) || '-'}
                />
                <InputField
                  label="Total Bruto"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_with_customer_service_fee) || '-'}
                />
                <InputField
                  label="Total Keseluruhan"
                  disabled
                  value={setCurrency(newOrderData?.data?.total_payment) || '-'}
                />
                <InputField
                  label="Harga Selisih"
                  disabled
                  value={setCurrency(newOrderData?.data?.total_diff_price_with_approval) || '-'}
                />
              </div>
            </div>

            <div className="detail-order__voucher-detail">
              <div className="detail-order__voucher-detail-title">
                <h1>Voucher</h1>
              </div>
              <div className="detail-order__voucher-detail-body">
                <InputField label="Nama Voucher" disabled value={newOrderData?.data?.vouchers?.[0].name || '-'} />
                <InputField label="Kode Voucher" disabled value={newOrderData?.data?.vouchers?.[0].code || '-'} />
                <InputField
                  label="Harga Potongan"
                  disabled
                  value={setCurrency(newOrderData?.data?.vouchers?.[0].value) || '-'}
                />
              </div>
            </div>

            <div className="detail-order__payment-detail">
              <div className="detail-order__payment-detail-title">
                <h1>Pembayaran</h1>
              </div>
              <div className="detail-order__payment-detail-body">
                <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                <div className="detail-order__payment-detail-body__payment-method">
                  <InputField label="Metode Pembayaran" disabled value={getPaymentMethodString(data?.disbursement)} />
                </div>

                {Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                  <>
                    <InputField
                      label="Nama Rekening"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_name
                          : data?.disbursement?.sender_name || '-'
                      }
                    />

                    <InputField
                      label="Nama Bank"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                          : data?.disbursement?.sender_bank_name || '-'
                      }
                    />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="Foto Bukti Transfer"
                        disabled
                        value={getFileName(
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                            : data?.disbursement.disbursement_confirmation_image,
                        )}
                        className="preview-image-input"
                      />

                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement.disbursement_confirmation_image,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}

        <div className="detail-order__transaction-buttons">
          <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
            Kembali
          </Button>

          {newOrderData?.status === 'pending' ? (
            <Button className="button" width={208} size="sm" onClick={() => confirmHandler('processed')}>
              Process
            </Button>
          ) : newOrderData?.status === 'processed' ? (
            <>
              <Button
                size="sm"
                className="button"
                width={208}
                variant="danger"
                onClick={() => confirmHandler('rejected')}
              >
                Tolak
              </Button>
              <Button className="button" width={208} size="sm" onClick={() => confirmHandler('approved')}>
                Konfirmasi
              </Button>
            </>
          ) : null}
        </div>
      </TableWrapper>
    </div>
  );
};

export default WithDriverDetail;
