import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWithdraw } from 'features/drivers/actions';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { checkEmptyObject } from 'utils/functionality';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'No. Handphone', value: 'driver_phone' },
  { header: 'Status', value: 'status' },
];

const WithdrawList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: withdrawData, isLoading: withdrawDataLoading } = useSelector((state) => state.withdraw);

  const [currentPage, setCurrentPage] = useState(1);
  const [newWithdrawData, setNewWithdrawData] = useState([]);

  const detailhandler = (e) => {
    const id = e.target.parentElement.parentElement.dataset.key;

    navigate(`/driver/withdraw?id=${id}`);
  };

  useEffect(() => {
    dispatch(getAllWithdraw({ page: currentPage, limit: 10 }));
  }, [currentPage]);

  useEffect(() => {
    if (!withdrawData || checkEmptyObject(withdrawData)) return;

    const mappingNewWithdrawData = withdrawData.data?.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      driver_phone: item.phone,
      status: item.status,
    }));

    setNewWithdrawData(mappingNewWithdrawData);
  }, [withdrawData]);

  return (
    <TableWrapper
      icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
      title="Withdraw"
      CustomFilterComponent={true}
      style={{ minHeight: '700px' }}
    >
      {withdrawDataLoading ? (
        <LoadingSpinner />
      ) : !withdrawData.data || withdrawData.data.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Table column={column} actionBtn onDetail={detailhandler} data={newWithdrawData} currentPage={currentPage} />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={withdrawData?.pagination?.total}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default WithdrawList;
