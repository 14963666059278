import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { indonesianDateFormat } from './helpers';
import { getAirportPackageById } from 'features/orders/actions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getDetailDriver } from 'features/drivers/actions';

const useCreateTableData = (data = []) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const [airportVehicleType, setAirportVehicleType] = useState([]);
  const [driver, setDriver] = useState([]);

  const getAirportTypeTitle = (id) => {
    const selectedData = airportVehicleType?.filter((item) => item?.id === id);
    const isDataEmpty = selectedData?.length === 0 || selectedData === undefined;

    if (isDataEmpty) return '-';
    return selectedData[0]?.title;
  };

  useEffect(() => {
    const getAirportTransferVehicleOrder = async () => {
      if (data?.length > 0) {
        if (data[0].order_type_id !== 2) return;
        const airportTransferIdToFetch = [];

        data.forEach((item) => {
          airportTransferIdToFetch.push({
            id: item.airport_transfer_package_id,
            pickupTrip: `${item?.order_detail?.start_booking_date} ${item?.order_detail?.start_booking_time.slice(
              0,
              5,
            )}`,
          });
        });

        const promises = airportTransferIdToFetch.map((item) =>
          dispatch(getAirportPackageById({ id: item.id, pickupTrip: item.pickupTrip })).unwrap(),
        );

        const results = await Promise.allSettled(promises);
        const fulfilledResults = results.filter((result) => result.status === 'fulfilled').map((item) => item.value);
        setAirportVehicleType(fulfilledResults);
      }
    };

    getAirportTransferVehicleOrder();
  }, [data]);

  const getDriverCar = (car, driverId) => {
    if (location.pathname !== '/driver' && searchParams.get('tab-active') !== 'Driver Task') return;
    if (driver)
      if (car === 'driver_car') {
        if (!driverId) {
          return '-';
        } else {
          const selectedDriver = driver.find((item) => item.id === driverId);
          if (selectedDriver) {
            return selectedDriver?.vehicle?.name;
          } else {
            return '-';
          }
        }
      } else {
        return car;
      }
  };

  useEffect(() => {
    if (location.pathname !== '/driver' && searchParams.get('tab-active') !== 'Driver Task') return;
    const getDriverData = async () => {
      if (data?.length > 0) {
        const driverIdToFetch = data
          .map((item) => item.driver_id)
          .filter((item) => item !== '')
          .filter((item, index, self) => self.indexOf(item) === index);

        if (driverIdToFetch.length === 0) return;

        const promises = driverIdToFetch.map((item) => dispatch(getDetailDriver(item)).unwrap());
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results.filter((result) => result.status === 'fulfilled').map((item) => item.value);
        setDriver(fulfilledResults);
      }
    };

    getDriverData();
  }, [data]);

  const tableData = data.map((item) => ({
    id: item?.id,
    name: item?.user_name,
    car_type: item?.order_detail?.vehicle?.name || '-',
    package_type: getAirportTypeTitle(item?.airport_transfer_package_id),
    start_date: indonesianDateFormat(item?.order_detail?.start_booking_date),
    end_date: indonesianDateFormat(item?.order_detail?.end_booking_date),
    start_time: item?.order_detail?.start_booking_time?.slice(0, 5),
    total_payment: item?.total_payment,
    status: item?.order_approval_status ? 'waiting_approval' : item.order_status.toLowerCase(),
    status_cancelation: item.order_cancelation?.Status,
    rent_status: 'Perpanjang Sewa',
    status_task: !item.order_driver_tasks
      ? 'not_proceed'
      : item.order_driver_tasks[item.order_driver_tasks.length - 1].status === 'BOOKED'
      ? 'booked-task'
      : item.order_driver_tasks[item.order_driver_tasks.length - 1].status.toLowerCase(),
    deposit_status: item.is_deposit_exists,
    deposit: item?.deposit || item.total_payment / 2,
    type: item?.type === 'FULL' ? 'FULL' : item?.payment_time !== null ? 'FULL' : 'HALF',
    order_approval_status: item?.order_approval_status || '',
    is_task_active: item?.is_running_driver_task,
    is_running_driver_task: item?.is_running_driver_task,
    delivery_location: item.order_detail.rental_delivery_location || '',
    delivery_location_detail: item.order_detail.rental_delivery_location_detail || '',
    rent_time: item?.rent_time,
    fee: item?.fee,
    driver_id: item?.driver_id || '-',
    task_id: item?.task_id,
    is_driver_task: item?.is_driver_task,
    task_type: item?.task_type,
    car: getDriverCar(item?.car, item?.driver_id),
    order_driver_tasks: item?.order_driver_tasks,
  }));

  return { data: tableData };
};

export default useCreateTableData;
