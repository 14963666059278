import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getDetailWithdraw, uploadTransferProofWithdraw } from 'features/drivers/actions';
import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as CarIcon } from '../../../icons/car-blue-bg.svg';

const TransferWithdrawFee = () => {
  const dispatch = useDispatch();

  // context
  const { setShowTransferWithdrawFee, setShowPreviewImageIdentity, showToast } = useAppContext();

  const targetRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const onImageChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newImages = e.target.files;
    // check if there are files
    if (newImages) {
      for (let image of newImages) {
        const imageUrl = URL.createObjectURL(image);
        setImageFile({ image: image, imageUrl });
      }
    }
  };

  const uploadTransfer = async () => {
    if (!imageFile) {
      return showToast({ type: 'error', message: 'Upload Bukti Transfer' });
    }

    try {
      await dispatch(uploadTransferProofWithdraw({ image: imageFile.image, id })).unwrap();
      dispatch(getDetailWithdraw(id));
      showToast({ type: 'success', message: 'Berhasil Upload Foto' });
      dispatch(getDetailWithdraw(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Gagal Upload Foto' });
    }
  };

  return (
    <Modals setState={setShowTransferWithdrawFee} title="Bukti Transfer" icon={<CarIcon width="25px" height="25px" />}>
      <div className="transfer-withdraw-fee">
        <h2>Bukti Transfer</h2>

        <div className="input-wrapper">
          <InputField value={imageFile?.image?.name || ''} readOnly label="Upload Bukti Transfer (Optional)" />
          <input
            type="file"
            name="upload-image"
            id="upload-image"
            accept="image/*"
            hidden
            ref={targetRef}
            onChange={onImageChange}
          />
          <div className="buttons">
            {!imageFile ? (
              <Button className="browse" width={66} height={25} onClick={() => targetRef.current.click()}>
                Browse
              </Button>
            ) : (
              <>
                <Button className="delete" width={66} height={25} onClick={() => setImageFile(null)}>
                  Hapus
                </Button>
                <Button
                  className="view"
                  width={66}
                  height={25}
                  onClick={() => setShowPreviewImageIdentity({ image: imageFile.imageUrl, show: true })}
                >
                  Lihat
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="action-btn">
          <Button variant="outline" width={131} onClick={() => setShowTransferWithdrawFee(false)}>
            Kembali
          </Button>
          <Button type="button" width={149} onClick={uploadTransfer}>
            Simpan dan Tutup
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default TransferWithdrawFee;
